import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import Loader from 'react-loader-spinner';

// components
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Youtube from '../../components/Youtube';
import Aluguel from '../../components/Aluguel';

//HTML-PARSER
import ReactHtmlParser from 'react-html-parser';

import { About } from './styles';

import api from '../../services/api';

export default function Sobre() {
  const [dados, setDados] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api.get('/wp/v2/pages?slug=quem-somos').then((response) => {
      setDados(response.data[0]);
      setTimeout(100);
      setLoading(false);
    });
  }, []);
  if (loading) {
    return (
      <>
        <Helmet>
          <title>PRM - Empreendimentos</title>
        </Helmet>
        <Header />
        <About>
          <div className="banner">
            <img
              src={require('../../assets/img/bannerSobre.png')}
              alt="bannerSobre"
            />
          </div>
          <div className="Text">
            <p className="headerList">Quem Somos</p>
            <div className="Line" />
            <div className="boxText">
              <p className="footerList">
              <Loader
                className="Loader"
                type="Audio"
                color="#004291"
                height={50}
                width={50}
              />
              </p>
            </div>
            <div className="boxOrange" />
            <div className="lineBlue" />
          </div>
          <div className="boxSobre">
            <div className="firstBox">
              <p className="headerText">Missão</p>
              <p className="footerText">
                Contribuir na realização de sonhos para nossos clientes, gerando
                melhorias na qualidade de vida de todos os envolvidos em nossos
                negócios.
              </p>
            </div>
            <div className="secondBox">
              <img
                src={require('../../assets/icon/IconEyes.svg')}
                alt="IconEyes"
              />
            </div>
            <div className="trirdBox">
              <p className="headerText">Valores</p>
              <p className="footerText">
                Os valores da PRM Empreendimentos são: Comprometimento, ética,
                responsabilidade socioambiental, espírito de Equipe e
                responsabilidade profissional.
              </p>
            </div>
            <div className="fourthBox">
              <img
                src={require('../../assets/icon/IconValores.svg')}
                alt="IconValores"
              />
            </div>
            <div className="fifthBox">
              <img
                src={require('../../assets/icon/IconTarget.svg')}
                alt="IconTarget"
              />
            </div>
            <div className="sixthBox">
              <p className="headerText">Visão</p>
              <p className="footerText">
                Ser reconhecida pela qualidade dos nossos produtos e serviços,
                perpetuando a rentabilidade, inovação e crescimento sustentável.
              </p>
            </div>
            <div className="seventhBox">
              <img
                src={require('../../assets/icon/IconQuality.svg')}
                alt="IconQuality"
              />
            </div>
            <div className="eighthBox">
              <p className="headerText">Política</p>
              <p className="footerText">
                Executar obras que atendam às necessidades dos nossos clientes e parceiros, buscando sempre a melhoria contínua, com o comprometimento técnico, ambiental e legal, garantindo a segurança e a satisfação de nossos clientes e colaboradores.
              </p>
            </div>
          </div>
        </About>
        <Youtube />
        <Aluguel />
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          <title>PRM - Empreendimentos</title>
        </Helmet>
        <Header />
        <About>
          <div className="banner">
            <img
              src={require('../../assets/img/bannerSobre.png')}
              alt="bannerSobre"
            />
          </div>
          <div className="Text">
            <p className="headerList">Quem Somos</p>
            <div className="Line" />
            <div className="boxText">
              <p className="footerList">
                {ReactHtmlParser(!!dados && dados.acf.texto)}
              </p>
            </div>
            <div className="boxOrange" />
            <div className="lineBlue" />
          </div>
          <div className="boxSobre">
            <div className="firstBox">
              <p className="headerText">Missão</p>
              <p className="footerText">
                Contribuir na realização de sonhos para nossos clientes, gerando
                melhorias na qualidade de vida de todos os envolvidos em nossos
                negócios.
              </p>
            </div>
            <div className="secondBox">
              <img
                src={require('../../assets/icon/IconEyes.svg')}
                alt="IconEyes"
              />
            </div>
            <div className="trirdBox">
              <p className="headerText">Valores</p>
              <p className="footerText">
                Os valores da PRM Empreendimentos são: Comprometimento, ética,
                responsabilidade socioambiental, espírito de Equipe e
                responsabilidade profissional.
              </p>
            </div>
            <div className="fourthBox">
              <img
                src={require('../../assets/icon/IconValores.svg')}
                alt="IconValores"
              />
            </div>
            <div className="fifthBox">
              <img
                src={require('../../assets/icon/IconTarget.svg')}
                alt="IconTarget"
              />
            </div>
            <div className="sixthBox">
              <p className="headerText">Visão</p>
              <p className="footerText">
                Ser reconhecida pela qualidade dos nossos produtos e serviços,
                perpetuando a rentabilidade, inovação e crescimento sustentável.
              </p>
            </div>
            <div className="seventhBox">
              <img
                src={require('../../assets/icon/IconQuality.svg')}
                alt="IconQuality"
              />
            </div>
            <div className="eighthBox">
              <p className="headerText">Política</p>
              <p className="footerText">
                Executar obras atendendo as necessidades de clientes, parceiros, legislações e requisitos da qualidade, promovendo sustentabilidade ambiental e melhoria contínua em suas atividades e processos.
              </p>
            </div>
          </div>
        </About>
        <Youtube />
        <Aluguel />
        <Footer />
      </>
    );
  }
}
