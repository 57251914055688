import React, { useState } from 'react';
// imports da aplicaçao
import { News } from './styles';
import { Content } from './styles';
import { Info } from './styles';
// imports de dependencias
import { useFormik } from 'formik';
import { notification } from 'antd';
import * as Yup from 'yup';
// imports de estilização
import { FiPhone } from 'react-icons/fi';
import { GoMail } from 'react-icons/go';
import { GrClock } from 'react-icons/gr';
import { FaInstagramSquare } from 'react-icons/fa';
import { AiFillFacebook } from 'react-icons/ai';

import ademail from '../../services/ademail';

export default function Footer() {
  const [disabledButton, setDisabledButton] = useState(false);
  const formCont = useFormik({
    initialValues: {
      email: '',
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('*Campo e-mail é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      setDisabledButton(true);
      const body = `
                      <p>E-mail: ${values.email}</p>
                    `;

      const mail = {
        to: 'comercial01@prmempreendimentos.com.br',
        from: values.email,
        as: values.email,
        subject: 'Novo newslatter via site - PRM Empreendimentos',
        message: body,
      };

      try {
        await ademail.post('/email', mail);

        notification.success({
          message: 'ENVIADO (TEXTO) 🚀',
          placement: 'bottomRight',
        });
        // Sucesso ao enviar
        resetForm();
        setDisabledButton(false);
      } catch (error) {
        // Erro ao enviar
        setDisabledButton(false);
        notification.error({
          message: 'FALHA (TEXTO)',
          description: 'FALHA (TEXTO)',
          placement: 'bottomRight',
        });
      }
    },
  });

  return (
    <>
      <News>
        <div className="News">
          <div className="firstCollum">
            <img
              src={require('../../assets/icon/mailFooter.svg')}
              alt="mailFooter"
            />
            <div className="newsText">
              Assine nossa <b>Newsletter</b> e receba todas as novidades da{' '}
              <b>PRM Empreendimentos</b>
            </div>
          </div>
          <form onSubmit={formCont.handleSubmit}>
            <div className="inputEmail">
              <input
                type="email"
                name="email"
                placeholder="E-mail"
                value={formCont.values.email}
                onChange={formCont.handleChange}
                onBlur={formCont.handleBlur}
              />
              {formCont.touched.email && formCont.errors.email ? (
                <span>{formCont.errors.email}</span>
              ) : null}
            </div>
            <button
              type="submit"
              className={disabledButton ? 'enable' : ''}
              disabled={disabledButton ? 'disabled' : ''}
            >
              <p>Enviar</p>
              <div className="bg" />
            </button>
            <div />
          </form>
        </div>
      </News>
      <Content>
        <div className="middleFooter">
          <div className="FCollum">
            <nav>
              <ul>
                <li>
                  <a href="/lancamentos">Lançamentos</a>
                </li>
                <li>
                  <a href="/imoveis">Imóveis à venda</a>
                </li>
                <li>
                  <a href="/imoveis">Entregues</a>
                </li>
                <li>
                  <a href="/#depoimentos">Depoimentos</a>
                </li>
                <li>
                  <a href="/quem-somos">Institucional</a>
                </li>
                <li>
                  <a href="/contato">Contato</a>
                </li>
                <li>
                <a href="/blog">Blog</a>
              </li>
              </ul>
            </nav>
            <div className="Certificacao">
              <div className="iso">
                <img src={require('../../assets/img/iso9001.jpg')} alt="iso" />
              </div>
              <div className="pbpq">
                <img src={require('../../assets/img/PBQP.png')} alt="PBQP" />
              </div>
              <div className="minhaCasa">
                <img
                  src={require('../../assets/img/minha_casa_minha_vida_logo.svg')}
                  alt="minhacasa"
                  style={{width: "70%"}}
                />
              </div>
              <div className="Caixa">
                <img src={require('../../assets/img/caixa.png')} alt="caixa" />
              </div>
            </div>
          </div>
          <div className="SCollum">
            <div className="LogoF">
              <a href="/" className="LogoIcon">
                <img
                  src={require('../../assets/marca/LogoFooter.svg')}
                  alt="LogoFooter"
                />
              </a>
            </div>
            <div className="RedeSocial">
              <a
                href="https://www.facebook.com/prmempreendimentos/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillFacebook className="Facebook" />
              </a>
              <a
                href="https://www.instagram.com/prm_empreendimentos/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagramSquare className="Instagram" />
              </a>
            </div>
          </div>
          <div className="TCollum">
            <div className="Whats">
              <a href="https://api.whatsapp.com/send?phone=5541991466488&text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20saber%20sobre%20"> </a>
            </div>
            <div className="Contato">
              <div className="Tel">
                <FiPhone className="FiPhone" />
                <a href="tel: +554135324224">(41) 3532.4224</a>
                <a href="tel: +554133282442">(41) 3328.2442</a>
              </div>
              <div className="line" />
              <div className="Mail">
                <GoMail className="GoMail" />
                <a href="mailto: contato@prmempreendimentos.com.br">
                  contato@prmempreendimentos.com.br
                </a>
              </div>
              <div className="line" />
              <div className="Glock">
                <GrClock className="GrGlock" />
                <p>Seg. a Sex. das 8h às 18h</p>
              </div>
            </div>
          </div>
        </div>
      </Content>
      <Info>
        <div>
          <p>©2020 PRM Empreendimentos. Todos os direitos reservados</p>
          <a
            href="https://agenciaade.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Feito com{' '}
            <img src={require('../../assets/icon/heart.svg')} alt="Coração" />{' '}
            por agenciaade.com.br
          </a>
        </div>
      </Info>
    </>
  );
}
